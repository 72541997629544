const STATE_CONSTITUENCIES_WITH_RESULTS = [
  {
    Wahlkreis: 45,
    Wahlberechtigte: 30574,
    Wählende: 23841,
    Gültig: 23569,
    Ungültig: 272,
    CDU: 7007,
    "DIE LINKE": 977,
    SPD: 6046,
    GRUENE: 4340,
    AfD: 1097,
    FDP: 2878,
    Sonstige: 1224,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-05",
  },
  {
    Wahlkreis: 42,
    Wahlberechtigte: 30949,
    Wählende: 24748,
    Gültig: 24460,
    Ungültig: 288,
    CDU: 6634,
    "DIE LINKE": 1150,
    SPD: 6662,
    GRUENE: 4976,
    AfD: 1121,
    FDP: 2565,
    Sonstige: 1352,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-02",
  },
  {
    Wahlkreis: 67,
    Wahlberechtigte: 30089,
    Wählende: 25912,
    Gültig: 25671,
    Ungültig: 241,
    CDU: 8591,
    "DIE LINKE": 748,
    SPD: 5460,
    GRUENE: 5831,
    AfD: 1066,
    FDP: 2691,
    Sonstige: 1284,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-07",
  },
  {
    Wahlkreis: 44,
    Wahlberechtigte: 32106,
    Wählende: 25117,
    Gültig: 24824,
    Ungültig: 293,
    CDU: 5008,
    "DIE LINKE": 1425,
    SPD: 6870,
    GRUENE: 6667,
    AfD: 944,
    FDP: 2598,
    Sonstige: 1312,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-04",
  },
  {
    Wahlkreis: 126,
    Wahlberechtigte: 28220,
    Wählende: 24353,
    Gültig: 23958,
    Ungültig: 395,
    CDU: 8169,
    "DIE LINKE": 677,
    SPD: 5337,
    GRUENE: 4374,
    AfD: 1398,
    FDP: 2373,
    Sonstige: 1630,
    "AGH-Wahlkreis": "Reinickendorf-06",
  },
  {
    Wahlkreis: 63,
    Wahlberechtigte: 33692,
    Wählende: 28084,
    Gültig: 27760,
    Ungültig: 324,
    CDU: 8280,
    "DIE LINKE": 931,
    SPD: 6805,
    GRUENE: 6292,
    AfD: 1295,
    FDP: 2693,
    Sonstige: 1464,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-03",
  },
  {
    Wahlkreis: 123,
    Wahlberechtigte: 30489,
    Wählende: 24399,
    Gültig: 23982,
    Ungültig: 417,
    CDU: 7181,
    "DIE LINKE": 787,
    SPD: 6128,
    GRUENE: 3532,
    AfD: 1919,
    FDP: 2316,
    Sonstige: 2119,
    "AGH-Wahlkreis": "Reinickendorf-03",
  },
  {
    Wahlkreis: 66,
    Wahlberechtigte: 31637,
    Wählende: 26699,
    Gültig: 26392,
    Ungültig: 307,
    CDU: 8249,
    "DIE LINKE": 1024,
    SPD: 5965,
    GRUENE: 6128,
    AfD: 1123,
    FDP: 2525,
    Sonstige: 1378,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-06",
  },
  {
    Wahlkreis: 85,
    Wahlberechtigte: 33352,
    Wählende: 24688,
    Gültig: 24099,
    Ungültig: 589,
    CDU: 6528,
    "DIE LINKE": 1177,
    SPD: 6996,
    GRUENE: 2733,
    AfD: 2376,
    FDP: 2275,
    Sonstige: 2014,
    "AGH-Wahlkreis": "Neukoelln-05",
  },
  {
    Wahlkreis: 86,
    Wahlberechtigte: 32878,
    Wählende: 24546,
    Gültig: 23901,
    Ungültig: 645,
    CDU: 7544,
    "DIE LINKE": 942,
    SPD: 6642,
    GRUENE: 2081,
    AfD: 2630,
    FDP: 2238,
    Sonstige: 1824,
    "AGH-Wahlkreis": "Neukoelln-06",
  },
  {
    Wahlkreis: 55,
    Wahlberechtigte: 32006,
    Wählende: 25011,
    Gültig: 24537,
    Ungültig: 474,
    CDU: 7512,
    "DIE LINKE": 825,
    SPD: 7580,
    GRUENE: 2944,
    AfD: 2027,
    FDP: 2269,
    Sonstige: 1380,
    "AGH-Wahlkreis": "Spandau-05",
  },
  {
    Wahlkreis: 76,
    Wahlberechtigte: 32143,
    Wählende: 23761,
    Gültig: 23256,
    Ungültig: 505,
    CDU: 7661,
    "DIE LINKE": 837,
    SPD: 6042,
    GRUENE: 2889,
    AfD: 2040,
    FDP: 2145,
    Sonstige: 1642,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-06",
  },
  {
    Wahlkreis: 124,
    Wahlberechtigte: 33852,
    Wählende: 25382,
    Gültig: 24804,
    Ungültig: 578,
    CDU: 6320,
    "DIE LINKE": 1149,
    SPD: 6567,
    GRUENE: 3531,
    AfD: 2458,
    FDP: 2262,
    Sonstige: 2517,
    "AGH-Wahlkreis": "Reinickendorf-04",
  },
  {
    Wahlkreis: 11,
    Wahlberechtigte: 30844,
    Wählende: 25540,
    Gültig: 25349,
    Ungültig: 191,
    CDU: 3963,
    "DIE LINKE": 2568,
    SPD: 4697,
    GRUENE: 9206,
    AfD: 867,
    FDP: 2291,
    Sonstige: 1757,
    "AGH-Wahlkreis": "Mitte-01",
  },
  {
    Wahlkreis: 46,
    Wahlberechtigte: 31618,
    Wählende: 24904,
    Gültig: 24529,
    Ungültig: 375,
    CDU: 4852,
    "DIE LINKE": 1543,
    SPD: 7114,
    GRUENE: 6548,
    AfD: 923,
    FDP: 2189,
    Sonstige: 1360,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-06",
  },
  {
    Wahlkreis: 65,
    Wahlberechtigte: 29803,
    Wählende: 22528,
    Gültig: 22078,
    Ungültig: 450,
    CDU: 6189,
    "DIE LINKE": 991,
    SPD: 5936,
    GRUENE: 3589,
    AfD: 1655,
    FDP: 1970,
    Sonstige: 1748,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-05",
  },
  {
    Wahlkreis: 47,
    Wahlberechtigte: 32503,
    Wählende: 26054,
    Gültig: 25744,
    Ungültig: 310,
    CDU: 5708,
    "DIE LINKE": 1399,
    SPD: 7551,
    GRUENE: 5967,
    AfD: 1233,
    FDP: 2284,
    Sonstige: 1602,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-07",
  },
  {
    Wahlkreis: 54,
    Wahlberechtigte: 31533,
    Wählende: 22727,
    Gültig: 22147,
    Ungültig: 580,
    CDU: 5687,
    "DIE LINKE": 922,
    SPD: 7667,
    GRUENE: 1948,
    AfD: 2409,
    FDP: 1964,
    Sonstige: 1550,
    "AGH-Wahlkreis": "Spandau-04",
  },
  {
    Wahlkreis: 64,
    Wahlberechtigte: 29223,
    Wählende: 23027,
    Gültig: 22654,
    Ungültig: 373,
    CDU: 6752,
    "DIE LINKE": 833,
    SPD: 6037,
    GRUENE: 4080,
    AfD: 1470,
    FDP: 2003,
    Sonstige: 1479,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-04",
  },
  {
    Wahlkreis: 32,
    Wahlberechtigte: 33155,
    Wählende: 25609,
    Gültig: 25229,
    Ungültig: 380,
    CDU: 4593,
    "DIE LINKE": 3183,
    SPD: 6019,
    GRUENE: 4303,
    AfD: 2887,
    FDP: 2170,
    Sonstige: 2074,
    "AGH-Wahlkreis": "Pankow-02",
  },
  {
    Wahlkreis: 77,
    Wahlberechtigte: 32662,
    Wählende: 25055,
    Gültig: 24599,
    Ungültig: 456,
    CDU: 9015,
    "DIE LINKE": 792,
    SPD: 6001,
    GRUENE: 2917,
    AfD: 2099,
    FDP: 2113,
    Sonstige: 1662,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-07",
  },
  {
    Wahlkreis: 75,
    Wahlberechtigte: 32978,
    Wählende: 23514,
    Gültig: 23053,
    Ungültig: 461,
    CDU: 5855,
    "DIE LINKE": 1335,
    SPD: 6557,
    GRUENE: 3531,
    AfD: 1835,
    FDP: 1951,
    Sonstige: 1989,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-05",
  },
  {
    Wahlkreis: 41,
    Wahlberechtigte: 28879,
    Wählende: 20390,
    Gültig: 19777,
    Ungültig: 613,
    CDU: 3568,
    "DIE LINKE": 1639,
    SPD: 5901,
    GRUENE: 4117,
    AfD: 1478,
    FDP: 1669,
    Sonstige: 1405,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-01",
  },
  {
    Wahlkreis: 53,
    Wahlberechtigte: 32458,
    Wählende: 22167,
    Gültig: 21598,
    Ungültig: 569,
    CDU: 4436,
    "DIE LINKE": 1443,
    SPD: 7121,
    GRUENE: 2745,
    AfD: 2197,
    FDP: 1815,
    Sonstige: 1841,
    "AGH-Wahlkreis": "Spandau-03",
  },
  {
    Wahlkreis: 31,
    Wahlberechtigte: 34791,
    Wählende: 25579,
    Gültig: 25095,
    Ungültig: 484,
    CDU: 4488,
    "DIE LINKE": 3351,
    SPD: 6200,
    GRUENE: 2987,
    AfD: 3701,
    FDP: 2083,
    Sonstige: 2285,
    "AGH-Wahlkreis": "Pankow-01",
  },
  {
    Wahlkreis: 62,
    Wahlberechtigte: 31292,
    Wählende: 24825,
    Gültig: 24440,
    Ungültig: 385,
    CDU: 5329,
    "DIE LINKE": 1481,
    SPD: 6558,
    GRUENE: 5815,
    AfD: 1407,
    FDP: 2028,
    Sonstige: 1822,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-02",
  },
  {
    Wahlkreis: 51,
    Wahlberechtigte: 34053,
    Wählende: 23062,
    Gültig: 22385,
    Ungültig: 677,
    CDU: 4923,
    "DIE LINKE": 1239,
    SPD: 7600,
    GRUENE: 2332,
    AfD: 2534,
    FDP: 1839,
    Sonstige: 1918,
    "AGH-Wahlkreis": "Spandau-01",
  },
  {
    Wahlkreis: 125,
    Wahlberechtigte: 26704,
    Wählende: 17019,
    Gültig: 16429,
    Ungültig: 590,
    CDU: 4422,
    "DIE LINKE": 820,
    SPD: 4667,
    GRUENE: 1404,
    AfD: 2118,
    FDP: 1332,
    Sonstige: 1666,
    "AGH-Wahlkreis": "Reinickendorf-05",
  },
  {
    Wahlkreis: 84,
    Wahlberechtigte: 33002,
    Wählende: 22018,
    Gültig: 21330,
    Ungültig: 688,
    CDU: 5959,
    "DIE LINKE": 1136,
    SPD: 6601,
    GRUENE: 1604,
    AfD: 2353,
    FDP: 1718,
    Sonstige: 1959,
    "AGH-Wahlkreis": "Neukoelln-04",
  },
  {
    Wahlkreis: 12,
    Wahlberechtigte: 33363,
    Wählende: 26087,
    Gültig: 25733,
    Ungültig: 354,
    CDU: 4147,
    "DIE LINKE": 4377,
    SPD: 5813,
    GRUENE: 5903,
    AfD: 1659,
    FDP: 2052,
    Sonstige: 1782,
    "AGH-Wahlkreis": "Mitte-02",
  },
  {
    Wahlkreis: 61,
    Wahlberechtigte: 32078,
    Wählende: 26166,
    Gültig: 25825,
    Ungültig: 341,
    CDU: 5569,
    "DIE LINKE": 1447,
    SPD: 6704,
    GRUENE: 7138,
    AfD: 1205,
    FDP: 2057,
    Sonstige: 1705,
    "AGH-Wahlkreis": "Steglitz-Zehlendorf-01",
  },
  {
    Wahlkreis: 43,
    Wahlberechtigte: 31034,
    Wählende: 24993,
    Gültig: 24694,
    Ungültig: 299,
    CDU: 4088,
    "DIE LINKE": 1859,
    SPD: 6907,
    GRUENE: 7498,
    AfD: 906,
    FDP: 1957,
    Sonstige: 1479,
    "AGH-Wahlkreis": "Charlottenburg-Wilmersdorf-03",
  },
  {
    Wahlkreis: 52,
    Wahlberechtigte: 29338,
    Wählende: 19263,
    Gültig: 18699,
    Ungültig: 564,
    CDU: 3789,
    "DIE LINKE": 1152,
    SPD: 6366,
    GRUENE: 2129,
    AfD: 2117,
    FDP: 1447,
    Sonstige: 1699,
    "AGH-Wahlkreis": "Spandau-02",
  },
  {
    Wahlkreis: 122,
    Wahlberechtigte: 28814,
    Wählende: 19273,
    Gültig: 18722,
    Ungültig: 551,
    CDU: 4578,
    "DIE LINKE": 1083,
    SPD: 4941,
    GRUENE: 2260,
    AfD: 2326,
    FDP: 1432,
    Sonstige: 2102,
    "AGH-Wahlkreis": "Reinickendorf-02",
  },
  {
    Wahlkreis: 38,
    Wahlberechtigte: 29440,
    Wählende: 24561,
    Gültig: 24245,
    Ungültig: 316,
    CDU: 2050,
    "DIE LINKE": 3850,
    SPD: 3950,
    GRUENE: 9943,
    AfD: 845,
    FDP: 1797,
    Sonstige: 1810,
    "AGH-Wahlkreis": "Pankow-08",
  },
  {
    Wahlkreis: 13,
    Wahlberechtigte: 30174,
    Wählende: 23210,
    Gültig: 22884,
    Ungültig: 326,
    CDU: 3624,
    "DIE LINKE": 2255,
    SPD: 5510,
    GRUENE: 7472,
    AfD: 911,
    FDP: 1680,
    Sonstige: 1432,
    "AGH-Wahlkreis": "Mitte-03",
  },
  {
    Wahlkreis: 112,
    Wahlberechtigte: 33320,
    Wählende: 24215,
    Gültig: 23745,
    Ungültig: 470,
    CDU: 3826,
    "DIE LINKE": 5738,
    SPD: 4739,
    GRUENE: 2074,
    AfD: 3347,
    FDP: 1734,
    Sonstige: 2287,
    "AGH-Wahlkreis": "Lichtenberg-02",
  },
  {
    Wahlkreis: 34,
    Wahlberechtigte: 28978,
    Wählende: 21802,
    Gültig: 21473,
    Ungültig: 329,
    CDU: 3463,
    "DIE LINKE": 3333,
    SPD: 5185,
    GRUENE: 3238,
    AfD: 2715,
    FDP: 1528,
    Sonstige: 2011,
    "AGH-Wahlkreis": "Pankow-04",
  },
  {
    Wahlkreis: 73,
    Wahlberechtigte: 33762,
    Wählende: 28113,
    Gültig: 27798,
    Ungültig: 315,
    CDU: 4444,
    "DIE LINKE": 1561,
    SPD: 7558,
    GRUENE: 9787,
    AfD: 949,
    FDP: 1928,
    Sonstige: 1571,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-03",
  },
  {
    Wahlkreis: 121,
    Wahlberechtigte: 28506,
    Wählende: 18151,
    Gültig: 17731,
    Ungültig: 420,
    CDU: 3563,
    "DIE LINKE": 1591,
    SPD: 4805,
    GRUENE: 2520,
    AfD: 2030,
    FDP: 1212,
    Sonstige: 2010,
    "AGH-Wahlkreis": "Reinickendorf-01",
  },
  {
    Wahlkreis: 94,
    Wahlberechtigte: 36006,
    Wählende: 27976,
    Gültig: 27612,
    Ungültig: 364,
    CDU: 4203,
    "DIE LINKE": 9515,
    SPD: 4136,
    GRUENE: 2589,
    AfD: 3168,
    FDP: 1844,
    Sonstige: 2157,
    "AGH-Wahlkreis": "Treptow-Koepenick-04",
  },
  {
    Wahlkreis: 93,
    Wahlberechtigte: 35563,
    Wählende: 26763,
    Gültig: 26283,
    Ungültig: 480,
    CDU: 4298,
    "DIE LINKE": 7744,
    SPD: 4423,
    GRUENE: 2027,
    AfD: 3763,
    FDP: 1753,
    Sonstige: 2275,
    "AGH-Wahlkreis": "Treptow-Koepenick-03",
  },
  {
    Wahlkreis: 71,
    Wahlberechtigte: 34172,
    Wählende: 26476,
    Gültig: 26133,
    Ungültig: 343,
    CDU: 3886,
    "DIE LINKE": 2049,
    SPD: 7149,
    GRUENE: 8900,
    AfD: 942,
    FDP: 1738,
    Sonstige: 1469,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-01",
  },
  {
    Wahlkreis: 74,
    Wahlberechtigte: 32794,
    Wählende: 25063,
    Gültig: 24694,
    Ungültig: 369,
    CDU: 4604,
    "DIE LINKE": 1889,
    SPD: 6831,
    GRUENE: 6470,
    AfD: 1317,
    FDP: 1614,
    Sonstige: 1969,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-04",
  },
  {
    Wahlkreis: 116,
    Wahlberechtigte: 30974,
    Wählende: 25029,
    Gültig: 24678,
    Ungültig: 351,
    CDU: 2989,
    "DIE LINKE": 6316,
    SPD: 4688,
    GRUENE: 4691,
    AfD: 2030,
    FDP: 1597,
    Sonstige: 2367,
    "AGH-Wahlkreis": "Lichtenberg-06",
  },
  {
    Wahlkreis: 24,
    Wahlberechtigte: 28504,
    Wählende: 22124,
    Gültig: 21829,
    Ungültig: 295,
    CDU: 2110,
    "DIE LINKE": 4684,
    SPD: 4340,
    GRUENE: 5793,
    AfD: 1316,
    FDP: 1368,
    Sonstige: 2218,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-04",
  },
  {
    Wahlkreis: 96,
    Wahlberechtigte: 33959,
    Wählende: 27293,
    Gültig: 26880,
    Ungültig: 413,
    CDU: 3739,
    "DIE LINKE": 10082,
    SPD: 4083,
    GRUENE: 2893,
    AfD: 2665,
    FDP: 1665,
    Sonstige: 1753,
    "AGH-Wahlkreis": "Treptow-Koepenick-06",
  },
  {
    Wahlkreis: 113,
    Wahlberechtigte: 34358,
    Wählende: 23456,
    Gültig: 22932,
    Ungültig: 524,
    CDU: 2799,
    "DIE LINKE": 5979,
    SPD: 4950,
    GRUENE: 2587,
    AfD: 2885,
    FDP: 1419,
    Sonstige: 2313,
    "AGH-Wahlkreis": "Lichtenberg-03",
  },
  {
    Wahlkreis: 95,
    Wahlberechtigte: 29932,
    Wählende: 22756,
    Gültig: 22331,
    Ungültig: 425,
    CDU: 3060,
    "DIE LINKE": 7826,
    SPD: 3630,
    GRUENE: 1449,
    AfD: 3140,
    FDP: 1375,
    Sonstige: 1851,
    "AGH-Wahlkreis": "Treptow-Koepenick-05",
  },
  {
    Wahlkreis: 37,
    Wahlberechtigte: 30217,
    Wählende: 23850,
    Gültig: 23503,
    Ungültig: 347,
    CDU: 2082,
    "DIE LINKE": 4348,
    SPD: 4511,
    GRUENE: 7203,
    AfD: 1623,
    FDP: 1446,
    Sonstige: 2290,
    "AGH-Wahlkreis": "Pankow-07",
  },
  {
    Wahlkreis: 33,
    Wahlberechtigte: 33430,
    Wählende: 26487,
    Gültig: 26139,
    Ungültig: 348,
    CDU: 3000,
    "DIE LINKE": 4536,
    SPD: 5744,
    GRUENE: 6998,
    AfD: 2122,
    FDP: 1593,
    Sonstige: 2146,
    "AGH-Wahlkreis": "Pankow-03",
  },
  {
    Wahlkreis: 39,
    Wahlberechtigte: 33394,
    Wählende: 25599,
    Gültig: 25234,
    Ungültig: 365,
    CDU: 2509,
    "DIE LINKE": 4344,
    SPD: 5204,
    GRUENE: 7452,
    AfD: 1722,
    FDP: 1528,
    Sonstige: 2475,
    "AGH-Wahlkreis": "Pankow-09",
  },
  {
    Wahlkreis: 26,
    Wahlberechtigte: 28352,
    Wählende: 23863,
    Gültig: 23684,
    Ungültig: 179,
    CDU: 1594,
    "DIE LINKE": 4080,
    SPD: 3206,
    GRUENE: 9993,
    AfD: 700,
    FDP: 1434,
    Sonstige: 2677,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-06",
  },
  {
    Wahlkreis: 111,
    Wahlberechtigte: 33914,
    Wählende: 22582,
    Gültig: 22017,
    Ungültig: 565,
    CDU: 3330,
    "DIE LINKE": 5007,
    SPD: 4552,
    GRUENE: 1302,
    AfD: 4227,
    FDP: 1269,
    Sonstige: 2330,
    "AGH-Wahlkreis": "Lichtenberg-01",
  },
  {
    Wahlkreis: 35,
    Wahlberechtigte: 30427,
    Wählende: 23342,
    Gültig: 23012,
    Ungültig: 330,
    CDU: 2614,
    "DIE LINKE": 4401,
    SPD: 5176,
    GRUENE: 5253,
    AfD: 2126,
    FDP: 1320,
    Sonstige: 2122,
    "AGH-Wahlkreis": "Pankow-05",
  },
  {
    Wahlkreis: 36,
    Wahlberechtigte: 31276,
    Wählende: 26183,
    Gültig: 25962,
    Ungültig: 221,
    CDU: 1761,
    "DIE LINKE": 4647,
    SPD: 4319,
    GRUENE: 11197,
    AfD: 814,
    FDP: 1458,
    Sonstige: 1766,
    "AGH-Wahlkreis": "Pankow-06",
  },
  {
    Wahlkreis: 15,
    Wahlberechtigte: 27997,
    Wählende: 19174,
    Gültig: 18765,
    Ungültig: 409,
    CDU: 2658,
    "DIE LINKE": 2477,
    SPD: 4964,
    GRUENE: 4529,
    AfD: 1483,
    FDP: 1038,
    Sonstige: 1616,
    "AGH-Wahlkreis": "Mitte-05",
  },
  {
    Wahlkreis: 17,
    Wahlberechtigte: 28450,
    Wählende: 18982,
    Gültig: 18576,
    Ungültig: 406,
    CDU: 2031,
    "DIE LINKE": 3003,
    SPD: 4528,
    GRUENE: 5451,
    AfD: 973,
    FDP: 1026,
    Sonstige: 1564,
    "AGH-Wahlkreis": "Mitte-07",
  },
  {
    Wahlkreis: 14,
    Wahlberechtigte: 29437,
    Wählende: 21895,
    Gültig: 21585,
    Ungültig: 310,
    CDU: 2067,
    "DIE LINKE": 3175,
    SPD: 4700,
    GRUENE: 7909,
    AfD: 866,
    FDP: 1181,
    Sonstige: 1687,
    "AGH-Wahlkreis": "Mitte-04",
  },
  {
    Wahlkreis: 92,
    Wahlberechtigte: 35678,
    Wählende: 26602,
    Gültig: 26202,
    Ungültig: 400,
    CDU: 3177,
    "DIE LINKE": 9698,
    SPD: 4002,
    GRUENE: 2720,
    AfD: 2892,
    FDP: 1433,
    Sonstige: 2280,
    "AGH-Wahlkreis": "Treptow-Koepenick-02",
  },
  {
    Wahlkreis: 115,
    Wahlberechtigte: 34014,
    Wählende: 25024,
    Gültig: 24587,
    Ungültig: 437,
    CDU: 2384,
    "DIE LINKE": 6653,
    SPD: 4630,
    GRUENE: 4202,
    AfD: 2579,
    FDP: 1335,
    Sonstige: 2804,
    "AGH-Wahlkreis": "Lichtenberg-05",
  },
  {
    Wahlkreis: 114,
    Wahlberechtigte: 34093,
    Wählende: 25263,
    Gültig: 24838,
    Ungültig: 425,
    CDU: 2367,
    "DIE LINKE": 7120,
    SPD: 4481,
    GRUENE: 4629,
    AfD: 2288,
    FDP: 1328,
    Sonstige: 2625,
    "AGH-Wahlkreis": "Lichtenberg-04",
  },
  {
    Wahlkreis: 21,
    Wahlberechtigte: 29423,
    Wählende: 22940,
    Gültig: 22670,
    Ungültig: 270,
    CDU: 1854,
    "DIE LINKE": 2988,
    SPD: 4389,
    GRUENE: 9792,
    AfD: 499,
    FDP: 1205,
    Sonstige: 1943,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-01",
  },
  {
    Wahlkreis: 25,
    Wahlberechtigte: 29129,
    Wählende: 23878,
    Gültig: 23704,
    Ungültig: 174,
    CDU: 1395,
    "DIE LINKE": 4508,
    SPD: 3471,
    GRUENE: 9501,
    AfD: 848,
    FDP: 1233,
    Sonstige: 2748,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-05",
  },
  {
    Wahlkreis: 72,
    Wahlberechtigte: 32989,
    Wählende: 26053,
    Gültig: 25716,
    Ungültig: 337,
    CDU: 2935,
    "DIE LINKE": 2210,
    SPD: 7313,
    GRUENE: 9527,
    AfD: 823,
    FDP: 1333,
    Sonstige: 1575,
    "AGH-Wahlkreis": "Tempelhof-Schoeneberg-02",
  },
  {
    Wahlkreis: 101,
    Wahlberechtigte: 31548,
    Wählende: 18815,
    Gültig: 18313,
    Ungültig: 502,
    CDU: 3325,
    "DIE LINKE": 4175,
    SPD: 3049,
    GRUENE: 966,
    AfD: 4055,
    FDP: 948,
    Sonstige: 1795,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-01",
  },
  {
    Wahlkreis: 83,
    Wahlberechtigte: 33294,
    Wählende: 21153,
    Gültig: 20592,
    Ungültig: 561,
    CDU: 2637,
    "DIE LINKE": 3713,
    SPD: 5363,
    GRUENE: 4489,
    AfD: 1311,
    FDP: 1010,
    Sonstige: 2069,
    "AGH-Wahlkreis": "Neukoelln-03",
  },
  {
    Wahlkreis: 103,
    Wahlberechtigte: 33210,
    Wählende: 20247,
    Gültig: 19779,
    Ungültig: 468,
    CDU: 3754,
    "DIE LINKE": 4446,
    SPD: 3392,
    GRUENE: 1045,
    AfD: 4161,
    FDP: 960,
    Sonstige: 2021,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-03",
  },
  {
    Wahlkreis: 104,
    Wahlberechtigte: 34953,
    Wählende: 26608,
    Gültig: 26135,
    Ungültig: 473,
    CDU: 8217,
    "DIE LINKE": 5947,
    SPD: 3782,
    GRUENE: 2054,
    AfD: 3154,
    FDP: 1253,
    Sonstige: 1728,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-04",
  },
  {
    Wahlkreis: 106,
    Wahlberechtigte: 31384,
    Wählende: 20801,
    Gültig: 20342,
    Ungültig: 459,
    CDU: 5365,
    "DIE LINKE": 4597,
    SPD: 3208,
    GRUENE: 1141,
    AfD: 3364,
    FDP: 938,
    Sonstige: 1729,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-06",
  },
  {
    Wahlkreis: 16,
    Wahlberechtigte: 27218,
    Wählende: 17618,
    Gültig: 17318,
    Ungültig: 300,
    CDU: 1512,
    "DIE LINKE": 3436,
    SPD: 3860,
    GRUENE: 5400,
    AfD: 838,
    FDP: 770,
    Sonstige: 1502,
    "AGH-Wahlkreis": "Mitte-06",
  },
  {
    Wahlkreis: 91,
    Wahlberechtigte: 35989,
    Wählende: 27358,
    Gültig: 26931,
    Ungültig: 427,
    CDU: 2665,
    "DIE LINKE": 10507,
    SPD: 3859,
    GRUENE: 4459,
    AfD: 2189,
    FDP: 1165,
    Sonstige: 2087,
    "AGH-Wahlkreis": "Treptow-Koepenick-01",
  },
  {
    Wahlkreis: 105,
    Wahlberechtigte: 33739,
    Wählende: 28761,
    Gültig: 28408,
    Ungültig: 353,
    CDU: 13877,
    "DIE LINKE": 4636,
    SPD: 2804,
    GRUENE: 2015,
    AfD: 2603,
    FDP: 1188,
    Sonstige: 1285,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-05",
  },
  {
    Wahlkreis: 102,
    Wahlberechtigte: 33154,
    Wählende: 21901,
    Gültig: 21373,
    Ungültig: 528,
    CDU: 5005,
    "DIE LINKE": 5587,
    SPD: 3657,
    GRUENE: 1095,
    AfD: 3573,
    FDP: 893,
    Sonstige: 1563,
    "AGH-Wahlkreis": "Marzahn-Hellersdorf-02",
  },
  {
    Wahlkreis: 23,
    Wahlberechtigte: 28501,
    Wählende: 20473,
    Gültig: 20123,
    Ungültig: 350,
    CDU: 1525,
    "DIE LINKE": 3468,
    SPD: 4023,
    GRUENE: 7763,
    AfD: 530,
    FDP: 828,
    Sonstige: 1986,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-03",
  },
  {
    Wahlkreis: 22,
    Wahlberechtigte: 26644,
    Wählende: 20815,
    Gültig: 20571,
    Ungültig: 244,
    CDU: 1037,
    "DIE LINKE": 3951,
    SPD: 3013,
    GRUENE: 9527,
    AfD: 392,
    FDP: 727,
    Sonstige: 1924,
    "AGH-Wahlkreis": "Friedrichshain-Kreuzberg-02",
  },
  {
    Wahlkreis: 81,
    Wahlberechtigte: 32225,
    Wählende: 24143,
    Gültig: 23812,
    Ungültig: 331,
    CDU: 1576,
    "DIE LINKE": 5487,
    SPD: 5014,
    GRUENE: 8354,
    AfD: 739,
    FDP: 738,
    Sonstige: 1904,
    "AGH-Wahlkreis": "Neukoelln-01",
  },
  {
    Wahlkreis: 82,
    Wahlberechtigte: 32286,
    Wählende: 23164,
    Gültig: 22811,
    Ungültig: 353,
    CDU: 1333,
    "DIE LINKE": 5443,
    SPD: 4914,
    GRUENE: 7843,
    AfD: 744,
    FDP: 656,
    Sonstige: 1878,
    "AGH-Wahlkreis": "Neukoelln-02",
  },
];

export default STATE_CONSTITUENCIES_WITH_RESULTS;
